<script>
export default {
  name: 'Icons',
  props: {
    w: {
      type: [Number, String],
      default: null,
    },
    h: {
      type: [Number, String],
      default: null,
    },
    color: {
      type: [String, Boolean],
      default: 'currentColor',
    },
  },
}
</script>
